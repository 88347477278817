import React, { useState } from 'react';
import LocalDetailsPopup from './LocalDetailsPopup'; // Import the new component
import OutstationDetailsPopup from './OutstationDetailsPopup'
import PuneAirportPopup from './PuneAirportPopup';
import MumbaiAirportPopup from './MumbaiAirportPopup';

const MotionCard = ({ title, description, imageUrl , type, carPackage,carPackageLoc,carPackageOut,price,extraHours, seats,puneAirportPac,mumbaiAirportPac}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [localDetailsPopupOpen, setLocalDetailsPopupOpen] = useState(false);
  const [outstationDetailsPopupOpen, setOutstationDetailsPopupOpen] = useState(false);
  const [puneAirportPopupOpen, setpuneAirportPopupOpen] = useState(false);
  const [mumbaiAirportPopupOpen, setmumbaiAirportPopupOpen] = useState(false);

  // Define some example details
  const localDetails = {
    Car: `${title}`,
    Price: `${price} ₹/km `,
    Package: `${carPackage}`,
  };

  const outstationDetails = {
    Car: `${title}`,
    Price: `${price}`,
    Package: `${type}`,
  };

  const puneAirportDetails = {
    Car: `${title}`,
    Price: `${price}`,
    Package: `Pune Airport`,
  };
  const mumbaiAirportDetails = {
    Car: `${title}`,
    Price: `${price}`,
    Package: `Mumbai Airport`,
  };

  const handleOutstationButtonClick = () => {
    setOutstationDetailsPopupOpen(true);
  };

  const handleCloseOutstationDetailsPopup = () => {
    setOutstationDetailsPopupOpen(false);
  };

  
  const handleLocalButtonClick = () => {
    setLocalDetailsPopupOpen(true);
  };

  const handleCloseLocalDetailsPopup = () => {
    setLocalDetailsPopupOpen(false);
  };

  const handlePuneAirportPopupClick = () => {
    setpuneAirportPopupOpen(true);
  };

  const handleClosePuneAirportPopup = () => {
    setpuneAirportPopupOpen(false);
  };

  const handleMumbaiAirportPopupClick = () => {
    setmumbaiAirportPopupOpen(true);
  };

  const handleCloseMumbaiAirportPopup = () => {
    setmumbaiAirportPopupOpen(false);
  };

  
  const mediaQueryStyle = {
    "@media (max-width: 768px)": {
      margin: "0 auto", // Center the grid on mobile devices
      textAlign: "center", // Center text horizontally
    },
  };
  const cardStyle = {
    position: "relative",
    width: "300px",
    height: "250px",
    borderRadius: "10px",
    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.5)",
    transition: "0.3s",
    padding: "10px 30px",
    background: "#fff",
    cursor: "pointer",
    marginRight: "30px",
    textAlign: "center", // Center text horizontally
    marginBottom: "60px", // Add top margin to prevent overlapping
    ...mediaQueryStyle, // Apply media query styles
  };

  const imgboxStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
    transform: "translateY(-80px)",
    zIndex: 99
  };

  const imgStyle = {
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.5)"
  };

  const contentStyle = {
    marginTop: "70px",
    padding: "10px 10px",
    transform: isHovered ? "translateY(-150px)" : "translateY(0)",
    opacity: isHovered ? 1 : 0,
    transition: "0.3s",
  };


  const newHeadingStyle = {
    margin: "0", // Remove default margin
    marginTop: "10px",// Add top margin
    fontSize: "25px", // Set font size
    color: "#333", // Text color
  };

  const details = {
    margin: "0", // Remove default margin
    marginTop: "20px",// Add top margin
    fontSize: "18px", // Set font size
    color: "#FFA500", // Text color
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "5px",
  };

  const buttonStyle = {
    padding: "10px 20px",
    width: "120px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };

  const localButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#6c8fef",
    color: "#fff",
  };

  const outstationButtonStyle = {
    ...buttonStyle,
    backgroundColor: "#6c8fef",
    color: "#fff",
  };

  const additionalButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  };
  
  const button1Style = {
    ...additionalButtonStyle,
    backgroundColor: "#6c8fef", // Customize the color for the first button
    color: "#fff",
  };
  
  const button2Style = {
    ...additionalButtonStyle,
    backgroundColor: "#6c8fef", // Customize the color for the second button
    color: "#fff",
  };

  

  return (
    <div
    style={{ ...cardStyle, ...mediaQueryStyle }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={imgboxStyle}>
        <img
          style={imgStyle}
          src={imageUrl}
          alt="Card"
        />
        <h2 style={newHeadingStyle}>{title}</h2>
        <h2 style={newHeadingStyle}>{`Price: ${price} ₹/Km`}</h2>
        {!isHovered && (
        <h2 style={details}>More Details</h2>
        )}
      </div>
      <div style={contentStyle}>
        {/* <h2>{isHovered ? `Price: ${price} ₹/Km` : `Price: ${price} ₹/Km`}</h2> */}
        {isHovered && (
          <>
          <div style={buttonContainerStyle}>
            <button style={localButtonStyle} onClick={handleLocalButtonClick}>Local</button>
            <button style={outstationButtonStyle} onClick={handleOutstationButtonClick}>Outstation</button>
            </div>
            <div style={buttonContainerStyle}>
             {/* Two additional buttons */}
        <button style={button1Style} onClick={handlePuneAirportPopupClick}>
          Pune Airport
        </button>
        <button style={button2Style} onClick={handleMumbaiAirportPopupClick}>
        Mumbai Airport
        </button>
          </div>
          </>
        )}
      </div>
   
         {/* Local Details Popup */}
         <LocalDetailsPopup carDetails={{ title, price,type, imageUrl, carPackage ,carPackageLoc,carPackageOut,extraHours, seats }} tripType={"Local"}
         open={localDetailsPopupOpen}
         onClose={handleCloseLocalDetailsPopup}
         details={localDetails}
       />
       {/* Outstation Details Popup */}
       <OutstationDetailsPopup carDetails={{ title, price,type, imageUrl, carPackage,carPackageLoc,carPackageOut,extraHours, seats }} tripType={"Outstation"}
         open={outstationDetailsPopupOpen}
         onClose={handleCloseOutstationDetailsPopup}
         details={outstationDetails}
       />

       {/* Pune Airport Popup*/}
       <PuneAirportPopup carDetails={{ title, price,type, imageUrl, carPackage,carPackageLoc,carPackageOut,extraHours, seats,puneAirportPac }} tripType={"Outstation"}
         open={puneAirportPopupOpen}
         onClose={handleClosePuneAirportPopup}
         details={puneAirportDetails}
       />

       {/* Mumbai Airport Popup*/}
       <MumbaiAirportPopup carDetails={{ title, price,type, imageUrl, carPackage,carPackageLoc,carPackageOut,extraHours, seats,mumbaiAirportPac }} tripType={"Outstation"}
         open={mumbaiAirportPopupOpen}
         onClose={handleCloseMumbaiAirportPopup}
         details={mumbaiAirportDetails}
       />
     </div>
  );
};

export default MotionCard;
