// src/components/Footer.js
import React from 'react';
import { Container, Grid, Typography, Link } from '@mui/material';
import styled from 'styled-components';

const FooterContainer = styled.div`
  background-color: #f8f9fa; /* Set your desired background color */
  padding: 20px 0;
  text-align: center;
`;

const LogoImage = styled.img`
  width: 40px; /* Set the desired width for the logos */
  height: auto;
  margin-right: 10px; /* Adjust the margin as needed */
`;

const HorizontalGridContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Swara Tours & Travels</Typography>
            <Typography variant="body2">
            Madhuban Society, Sinhgad Rd, opposite CWPRS Main Gate, Kolhewadi, Pune, Maharashtra 411024
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Quick Links</Typography>
            <Link href="#" color="inherit">
              Home
            </Link>
            <br />
            <Link href="#" color="inherit">
              About Us
            </Link>
            <br />
            <Link href="#" color="inherit">
              Services
            </Link>
            <br />
            <Link href="#" color="inherit">
              Contact Us
            </Link>
          </Grid>
          <HorizontalGridContainer item xs={12} md={4}>
            <Typography variant="h8">Connect With Us</Typography>
            <a href="#" target="_blank">
              <LogoImage src={require("./Images/fb2.png")} alt="Facebook Logo" />
            </a>
            <a href="#" target="_blank">
              <LogoImage src={require("./Images/twitter.png")} alt="Twitter Logo" />
            </a>
            <a href="#" target="_blank">
              <LogoImage src={require("./Images/insta.png")} alt="Instagram Logo" />
            </a>
           
          </HorizontalGridContainer>
        </Grid>
      </Container>
    </FooterContainer>
  );
};

export default Footer;
