// src/components/Logo.js

import React from 'react';

const Logo = () => {
  return (
    <div >
      <img src={require("./Images/logo4.JPG")} alt="Your Logo Alt Text" style={{ width: '300px', height: 'auto' }} />
    </div>
  );
};

export default Logo;
