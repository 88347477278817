import React from 'react';
import Grid from '@mui/material/Grid';
import MotionCard from './MotionCard';
import styled from 'styled-components';

const SectionContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 100px;
  text-align: center;
`;

const MotionCardGrid = ({ data }) => {
  return (
    <>  
    <h2 style={{ textAlign: 'center', marginTop: '30px' }}>Available Cars</h2>

    <SectionContainer>  
      <Grid container spacing={2} justifyContent="center">
        {data.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
            <MotionCard
              title={item.title}
              description={item.description}
              imageUrl={item.imageUrl}
              type={item.type}
              price={item.price}
              carPackage={item.carPackage}
              carPackageLoc={item.carPackageLoc}
              extraHours={item.extraHours}
              seats={item.seats}
              carPackageOut={item.carPackageOut}
              puneAirportPac={item.puneAirportPac}
              mumbaiAirportPac={item.mumbaiAirportPac}
            />
          </Grid>
        ))}
      </Grid>
    </SectionContainer>
    </>
  );
};

export default MotionCardGrid;
