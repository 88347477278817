import React from 'react';
import Logo from './Logo';

const Header = () => {
  return (
    <div style={headerStyle}>
      <div style={logoSlotStyle}>
        <Logo />
      </div>
      <div style={otherSlotStyle}>
        <div>
          <img src={require("./Images/banner5.jpg")} alt="Your Logo Alt Text" style={imageStyle} />
        </div>
      </div>
    </div>
  );
};

const headerStyle = {
  display: 'flex',
  flexDirection: 'column', // Stack elements vertically on small screens
  padding: '10px',
};

const logoSlotStyle = {
  marginBottom: '10px', // Add spacing between logo and image
};

const otherSlotStyle = {
  flex: '1', // Allow the second slot to take remaining space
};

const imageStyle = {
  width: '100%',
  height: 'auto', // Maintain the aspect ratio of the image
};

export default Header;
