// src/App.js
import React from 'react';
import Hero from './components/Hero';
import CardGrid from './components/CardGrid';
import Footer from './components/Footer';
import Header from './components/Header';
import MotionCardGrid from './components/MotionCardGrid';
import ResponsiveCardGrid from './components/ResponsiveCardGrid';

function App() {
 
  const cardData = [
    { title: 'Xcent', description: 'Price Details', imageUrl: require("./components/Images/xcent5.jpg"), type: 'Outstation',carPackage:'8 / 80', carPackageLoc:1800, carPackageOut:3600, price: 12, extraHours:150, seats:'4', puneAirportPac:'1300', mumbaiAirportPac:'3000' },
    { title: 'Maruti Dzire', description: 'Price Details', imageUrl: require("./components/Images/dzire2.webp"), type: 'Outstation',carPackage:'8 / 80',carPackageLoc:1800,carPackageOut:3600, price: 12, extraHours:150, seats:'4', puneAirportPac:'1300', mumbaiAirportPac:'3000' },
    { title: 'Wagon-R', description: 'Price Details', imageUrl: require("./components/Images/wagonr2.webp"), type: 'Outstation',carPackage:'8 / 80',carPackageLoc:1600,carPackageOut:3300, price: 11, extraHours:100, seats:'4', puneAirportPac:'1100', mumbaiAirportPac:'2800' },
    { title: 'Innova', description: 'Price Details', imageUrl: require("./components/Images/Innova8.jpg"), type: 'Outstation',carPackage:'8 / 80',carPackageLoc:3300,carPackageOut:5400, price: '16-18', extraHours:200, seats:'4 to 6', puneAirportPac:'2000', mumbaiAirportPac:'4000' },
    { title: 'Innova Crysta', description: 'Price Details', imageUrl: require("./components/Images/crysta.webp"), type: 'Outstation',carPackage:'8 / 80',carPackageLoc:3600,carPackageOut:6000, price: '18-20', extraHours:200, seats:'4 to 6', puneAirportPac:'3000', mumbaiAirportPac:'5000' },
    { title: 'Suzuki Ertiga', description: 'Price Details', imageUrl: require("./components/Images/ertiga.avif"), type: 'Outstation',carPackage:'8 / 80',carPackageLoc:2800,carPackageOut:4500, price: '14-15', extraHours:180, seats:'4 to 6', puneAirportPac:'2000', mumbaiAirportPac:'3500' },
    { title: 'Tavera', description: 'Price Details', imageUrl: require("./components/Images/tavera2.webp"), type: 'Outstation',carPackage:'8 / 80',carPackageLoc:3300,carPackageOut:5100, price: 17, extraHours:200, seats:'7 to 9', puneAirportPac:'2000', mumbaiAirportPac:'3750' },
    { title: 'Etios', description: 'Price Details', imageUrl: require("./components/Images/etios.jpg"), type: 'Outstation',carPackage:'8 / 80',carPackageLoc:2000,carPackageOut:3600, price: 12, extraHours:150, seats:'4', puneAirportPac:'1300', mumbaiAirportPac:'3000' },
    { title: 'Maruti Swift', description: 'Price Details', imageUrl: require("./components/Images/swift.png"), type: 'Outstation',carPackage:'8 / 80',carPackageLoc:1600,carPackageOut:3300, price: 11, extraHours:120, seats:'4', puneAirportPac:'1200', mumbaiAirportPac:'2500' }
    
    // Add more card data as needed
  ];

  return (
    <div className="App">
      <Header/>
      <Hero />
      <MotionCardGrid data={cardData}/>
      {/* <MotionCard/> */}
      {/* <ResponsiveCardGrid data={cardData} /> */}
      <CardGrid />
      <Footer />
    </div>
  );
}

export default App;