// src/components/CardGrid.js
import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

const SectionContainer = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

const StyledCard = styled(Card)`
  max-width: 300px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa; /* Background color on hover */
  }
`;

const StyledCardMedia = styled(CardMedia)`
  height: 0;
  padding-top: 100%; /* 1:1 aspect ratio for circular image */
  border-radius: 50%;
`;

const CardTitle = styled(Typography)`
  text-align: center;
  margin-top: 16px; /* Adjust the top margin as needed */
`;

const CenteredButton = styled(Button)`
  align-self: center;
  margin: 16px 0 30px; /* Center the button at the bottom and add bottom margin */
`;

const CardGrid = () => {
  const cardData = [
    // ... (previous card data remains the same)
    {
      id: 1,
      imageUrl: require("./Images/saibaba.jpg"),
      title: 'Shirdi Saibaba',
      description: 'Sai Baba of Shirdi, also known as Shirdi Sai Baba, was an Indian spiritual master and fakir, considered to be a saint, revered by both Hindu and Muslim devotees during and after his lifetime',
      buttonLink: 'https://example.com',
    },
    {
      id: 2,
      imageUrl: require("./Images/balaji.jpg"),
      title: 'Tirupati Balaji',
      description: 'The Sri Venkateswara Swami Temple is a Hindu temple situated in the hill town of Tirumala at Tirupati in Tirupati district of Andhra Pradesh, India',
      buttonLink: 'https://example.com',
    },
    // Add more card data as needed
    {
      id: 3,
      imageUrl: require("./Images/goa.jpg"),
      title: 'Goa',
      description: 'Goa is a state on the southwestern coast of India within the Konkan region, geographically separated from the Deccan highlands by the Western Ghats.',
      buttonLink: 'https://example.com',
    },
    {
      id: 4,
      imageUrl: require("./Images/lavasa.webp"),
      title: 'Lavasa',
      description: 'Lavasa is a hill-station famous for its adventure sports and activities, Lavasa Nature Trails and the Temghar Dam.',
      buttonLink: 'https://example.com',
    },
    {
      id: 5,
      imageUrl: require("./Images/mumbai.webp"),
      title: 'Mumbai',
      description: 'Mumbai, one of the major cosmopolitan cities in India, is a popular tourist attraction. It is primarily famous for large markets, mesmerising beaches, towering buildings, and exquisite five-star hotels.',
      buttonLink: 'https://example.com',
    },
    {
      id: 6,
      imageUrl: require("./Images/bhimashankar.jpg"),
      title: 'Bhimashankar',
      description: 'This famous temple is built in Nagara style. The temples unique design, resembling a chariot, is an exquisite example of architectural creativity. This distinctive feature sets it apart from other temples dedicated to Lord Shiva.',
      buttonLink: 'https://example.com',
    },
    {
      id: 7,
      imageUrl: require("./Images/lonavala.jpg"),
      title: 'Lonavala Khandala',
      description: 'Magical waterfalls, pristine hiking trails, breathtaking vantage points, ancient caves and historical forts - Lonavala Khandala has something to offer to everyone. Primary places to visit in Lonavala include Karla and Bhaja Caves, Rajmachi Fort and Tiger Point among many others.',
      buttonLink: 'https://example.com',
    },
    {
      id: 8,
      imageUrl: require("./Images/ganpati2.jpg"),
      title: 'Ashtavinayak Yatra',
      description: 'The Ashtavinayaka Yatra refers to a pilgrimage to the eight Hindu temples in the state of Maharashtra, India, centered around the city of Pune. The eight temples house eight distinct idols of Ganesha, the Hindu deity of unity, prosperity, learning, and removing obstacles.',
      buttonLink: 'https://example.com',
    },
    {
      id: 9,
      imageUrl: require("./Images/tuljapur.jpg"),
      title: 'Tulja Bhavani Temple',
      description: 'An ancient rock temple located in the city of Tuljapur, this Temple has an interesting mythological adage behind it. It is believed that Lord Rama passed through these rocks (Shila) along with his brother Lakshman, in the mountains (Ghats) to search for Goddess Sita – his wife.',
      buttonLink: 'https://example.com',
    },
    {
      id: 10,
      imageUrl: require("./Images/kolhapur.webp"),
      title: 'Kolhapur Mahalaxmi Temple',
      description: 'The Shri Mahalakshmi Temple of Kolhapur in Maharashtra, India, is one of the Shakti Peethas also called Dakshin Kashi, listed in various puranas of Hinduism. According to these writings, a shakti peetha is a place associated with Shakti, the goddess of power.',
      buttonLink: 'https://example.com',
    },
    {
      id: 11,
      imageUrl: require("./Images/gangapur.jpg"),
      title: 'Akkalkot - Ganagapur',
      description: 'Significance of Ganagapur as a holy land: Ganagapur is a Datta-kshetra and is associated with Sri Narasimha Saraswati Swami (1378-1458), believed to be the second incarnation of Lord Dattatreya, the first being Sripada Sri Vallabha (born 1320). It is believed that Sripada Srivallabha is immortal or Chiranjeevi',
      buttonLink: 'https://example.com',
    },
    {
      id: 12,
      imageUrl: require("./Images/pune2.jpeg"),
      title: 'Pune Darshan',
      description: 'Pune is a thriving metropolis in Maharashtra. It is among the most liveable cities in India. The city is home to renowned educational institutions and is also referred to as the Oxford of the East. It is also quickly becoming a bustling hub for the IT industry and other major industries.',
      buttonLink: 'https://example.com',
    },
    {
      id: 13,
      imageUrl: require("./Images/Nasik.jpg"),
      title: 'Nasik Trimbakeshwar Temple',
      description: 'Shri Trimbakeshwar is a religious center having one of the twelve Jyotirlingas. The extraordinary feature of the Jyotirlinga located here is its three faces embodying Brahma, Vishnu, and Shiva. Due to the excessive use of water, the linga has started to erode.',
      buttonLink: 'https://example.com',
    },
    {
      id: 14,
      imageUrl: require("./Images/konkan.avif"),
      title: 'Konkan',
      description: 'Konkan region is well famous for its long and narrow stretch of sparking beaches with crystal blue waters and white-black sand. Tarkarli beach of Sindhudurg, Ganpatipule beach and Dapoli of Ratnagiri, Harihareshwar and Diveagar beach of Raigad district are the few most famous beaches of Konkan.',
      buttonLink: 'https://example.com',
    },
    {
      id: 15,
      imageUrl: require("./Images/aurangabad.jpg"),
      title: 'Aurangabad',
      description: 'Aurangabad is a city in Maharashtra state, in India. It’s known for the 17th-century marble Bibi ka Maqbara shrine, styled on the Taj Mahal. The nearby Shivaji Maharaj Museum, dedicated to the Maratha king Shivaji, displays war weapons and a coin collection. North of the city, the Aurangabad Caves comprise ancient, rock-cut Buddhist shrines. West of the city, battlements surround the medieval Daulatabad Fort',
      buttonLink: 'https://example.com',
    },
  ];

  return (
    <SectionContainer>
      <h2>Explore Destinations</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {cardData.map((card) => (
          <StyledCard key={card.id}>
            <StyledCardMedia image={card.imageUrl} alt={`Card ${card.id}`} />
            <CardContent>
              <CardTitle variant="h6" color="textPrimary">
                {card.title}
              </CardTitle>
              <Typography variant="body2" color="textSecondary" component="p">
                {card.description}
              </Typography>
            </CardContent>
            {/* <CenteredButton variant="contained" color="primary" href={card.buttonLink} target="_blank">
              Details
            </CenteredButton> */}
          </StyledCard>
        ))}
      </div>
    </SectionContainer>
  );
};

export default CardGrid;
