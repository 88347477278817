// src/components/ResponsiveCardGrid.js
import React from 'react';
import Grid from '@mui/material/Grid';
import ResponsiveCard from './ResponsiveCard';
import styled from 'styled-components';

const SectionContainer = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

const ResponsiveCardGrid = ({ data }) => {
  return (
    <SectionContainer>
       <h2>Available Cars</h2>
    <Grid container spacing={2} justifyContent="center">
      {data.map((item, index) => (
        <Grid item key={index}>
          <ResponsiveCard
            title={item.title}
            description={item.description}
            imageUrl={item.imageUrl}
            type={item.type}
            price={item.price}
            carPackage={item.carPackage}
          />
        </Grid>
      ))}
    </Grid>
    </SectionContainer>
  );
};

export default ResponsiveCardGrid;
