// src/components/CarPopup.js
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import BookingPopup from './BookingPopup'; // Import the BookingPopup component

const PopupContainer = styled.div`
width: 100%;
max-width: 300px;
margin: auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
transform-origin: center center;
overflow: hidden;
`;

const CarImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  display: block;
  margin: 0 auto;
`;


const CarPopup = ({ open, onClose, carDetails  }) => {
  const { title, price, type, imageUrl,carPackage, } = carDetails;
  const [bookingPopupOpen, setBookingPopupOpen] = useState(false); // Add state for BookingPopup

  const handleBookClick = () => {
    setBookingPopupOpen(true);
    onClose(); // Close the CarPopup
  };

  const closeBookingPopup = () => {
    setBookingPopupOpen(false);
  };
  return (
    <>
    <Dialog open={open} onClose={onClose} aria-labelledby="car-popup-title">
      <PopupContainer>
        <DialogTitle id="car-popup-title">{title}</DialogTitle>
        <DialogContent>
          <CarImage src={imageUrl} alt={title} />
          <DialogContentText>
            <strong>Price:</strong> {price} ₹/Km
          </DialogContentText>
          <DialogContentText>
            <strong>Type:</strong> {type}
          </DialogContentText>
          {/* <DialogContentText>
            <strong>Package:</strong> {carPackage}₹
          </DialogContentText> */}
          <DialogContentText>
            <strong>Minimum Avg. Km:</strong> 300 km
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
          <Button color="primary" onClick={handleBookClick}>
            Book
          </Button>
        </DialogActions>
      </PopupContainer>
    </Dialog>
     {/* Include the BookingPopup */}
     <BookingPopup open={bookingPopupOpen} onClose={closeBookingPopup} carName={ title } />
     </>
  );
};

export default CarPopup;
