// src/components/ConfirmationPopup.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import styled from 'styled-components';

const PopupContainer = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
`;

const TitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: white;
  height: 40px;
  background-color: #4caf50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckSignImage = styled.img`
  width: 80px;
  margin: 30px auto 20px;
  display: block;
`;

const StyledButton = styled(Button)`
  width: 100%;
  color: white;
  background-color: #4caf50;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

const ConfirmationPopup = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="confirmation-popup-title">
      <PopupContainer>
        <TitleText>Booking Confirmed</TitleText>
        <CheckSignImage src={require('./Images/checked.png')} alt="Check Sign" />
        <StyledDialogContent>
          Booking has confirmed for the car you have selected our executive will reach you soon. Thanks!
        </StyledDialogContent>
        <DialogActions>
          <StyledButton onClick={onClose}>OK</StyledButton>
        </DialogActions>
      </PopupContainer>
    </Dialog>
  );
};

export default ConfirmationPopup;
