// src/components/BookingPopup.js
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import DialogContentText from '@mui/material/DialogContentText';
import ConfirmationPopup from './ConfirmationPopup';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';


const PopupContainer = styled.div`
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  padding: 10px;
`;

const ResponsiveTextField = styled(TextField)`
  && {
    margin-bottom: 10px;
  }
`;

const BookingPopup = ({ open, onClose, carName, tripType}) => {

  const initialFormData ={
    selectedCarName: carName,
    name: '',
    mobile: '',
    from: '',
    to: '',
    selectedDate: null,
    days: null,
  }
  const [formData, setFormData] = useState({ ...initialFormData });
  const [selectedQuantity, setSelectedQuantity] = useState(1);

  const resetForm = () => {
    onClose();
    setMobileError('');
    setNameError('');
    setFormData({ ...initialFormData });

  };

  // useEffect(() => {
  //   // Update selectedCarName when selectedCar changes
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     selectedCarName: selectedCar || '',
  //   }));
  // }, [selectedCar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedDate: date,
    }));
   
  };

  const handleQuantityChange = (event) => {
    setSelectedQuantity(event.target.value);
  };

  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);

  const handleSubmit2 = () => {
    // Handle form submission logic here
    // You can access the form input values and perform necessary actions
    setConfirmationPopupOpen(true);
    onClose();
  };

  const closeConfirmationPopup = () => {
    setConfirmationPopupOpen(false);
    resetForm();
    onClose(); // Close the BookingPopup after confirmation
  };

  const [nameError, setNameError] = useState('');

  const [mobileError, setMobileError] = useState('');

  const handleSubmit = async () => {
    // Validate the name before submitting the form
    const isNameValid = validateName();

    const isMobileValid = validateMobile();
  
    if (!isNameValid) {
      // Display an error message or handle the validation failure
      return;
    }

    if (!isMobileValid) {
      // Display an error message or handle the validation failure
      return;
    }
  
    try {
      // Make a POST request using axios
      const response = await axios.post('https://4zav9e9jyf.execute-api.us-east-1.amazonaws.com/Test/bookingdata', {
        car: carName,
        name: formData.name,
        mobileNo: parseInt(formData.mobile),
        From: formData.from,
        To: formData.to,
        journeyDate: formData.selectedDate,
        days: selectedQuantity,
        Type: tripType,
        bookingId: 2
      });
  
      // Handle the response or perform any other actions
      console.log('Booking successful:', response.data);
  
      // Show the confirmation popup
      setConfirmationPopupOpen(true);
      // Close the BookingPopup after confirmation
      resetForm();
      onClose();
    } catch (error) {
      // Handle errors
      console.error('Error submitting booking:', error.message);
    }
  };

  const validateName = () => {
    const nameRegex = /^[A-Za-z ]{1,12}$/;
    const isValid = nameRegex.test(formData.name);
  
    const isEmpty = formData.name.trim() !== '';

    if (!isEmpty) {
      setNameError('Please enter name');
    }else if (!isValid) {
      setNameError('Name should include only alphabets');
    }else {
      setNameError('');
    }
  
    return isValid;
  };

  const validateMobile =() =>{

    const nameRegex = /^\d{10}$/;
    const isValid = nameRegex.test(formData.mobile);
    const isEmpty = formData.mobile.trim() !== '';

    if (!isEmpty) {
      setMobileError('Please enter Mobile number');
    }else if (!isValid) {
      setMobileError('Mobile number must be 10 letters');
    }else {
      setMobileError('');
    }
  
    return isValid;
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} aria-labelledby="booking-popup-title">
        <PopupContainer>
          <DialogTitle id="booking-popup-title">Book {tripType} Trip</DialogTitle>

          <DialogContent>
            <DialogContentText>
              <ResponsiveTextField
                fullWidth
                label="Car"
                variant="outlined"
                margin="normal"
                name="selectedCarName"
                value={carName}
                onChange={handleChange}
                readOnly
              />
            </DialogContentText>
            <ResponsiveTextField
              fullWidth
              label="Name"
              variant="outlined"
              margin="normal"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={!!nameError}
              helperText={nameError}
            />
            <ResponsiveTextField
              fullWidth
              label="Mobile No"
              variant="outlined"
              margin="normal"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              error={!!mobileError}
              helperText={mobileError}
              InputProps={{
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  maxLength: 10,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <FormControl>
                      <img
                        src="https://flagsapi.com/IN/flat/64.png" // India flag image
                        alt="India Flag"
                        style={{
                          width: '1.5em', // Set the width to adjust the size
                          height: '1.5em', // Set the height to adjust the size
                          marginRight: '4px', // Adjust the margin as needed
                        }}
                      />
                    </FormControl>
                  </InputAdornment>
                ),
              }}
            />
            <ResponsiveTextField
              fullWidth
              label="Pick Up"
              variant="outlined"
              margin="normal"
              name="from"
              value={formData.from}
              onChange={handleChange}
            />
            <ResponsiveTextField
              fullWidth
              label="Drop"
              variant="outlined"
              margin="normal"
              name="to"
              value={formData.to}
              onChange={handleChange}
            />
               {/* Date Picker */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                value={formData.selectedDate}
                onChange={handleDateChange}
                renderInput={(params) => <ResponsiveTextField {...params} fullWidth />}
                format="DD MMM YYYY"
                disablePast  // Disable selecting past dates
              />
            </LocalizationProvider>
            <FormControl variant="outlined" style={{ marginLeft: 10 }}>
        <InputLabel id="quantity-label">Days</InputLabel>
        <Select
          labelId="quantity-label"
          id="quantity-select"
          value={selectedQuantity}
          onChange={handleQuantityChange}
          label="Days"
        >
          {[...Array(5).keys()].map((num) => (
            <MenuItem key={num + 1} value={num + 1}>
              {num + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          </DialogContent>

          <DialogActions  style={{ justifyContent: 'center' }}>
            <Button onClick={resetForm} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions >
        </PopupContainer>
      </Dialog>
      {/* ConfirmationPopup */}
      <ConfirmationPopup open={confirmationPopupOpen} onClose={closeConfirmationPopup} />
    </>
  );
};

export default BookingPopup;
