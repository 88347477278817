// src/components/ResponsiveCard.js
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import styled from 'styled-components';
import CarPopup from './CarPopup';


const SectionContainer = styled.div`
  margin-bottom: 30px;
`;
const HoverContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  text-align: center;
  border-radius: 8px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.3s;
  ${Card}:hover & {
    opacity: 1;
  }

  body2 {
    color: #777;
  }
`;

const ResponsiveCard = ({ title, description, imageUrl, carName, price, type, carPackage }) => {

  const [isHovered, setIsHovered] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleCardClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <SectionContainer>
    <StyledCard style={cardStyle}
    onClick={handleCardClick}
   
   
    >
      <CardMedia
        component="img"
        alt={title}
        height="140"
        image={imageUrl}
        style={mediaStyle}
      />
      {isHovered && (
          <HoverContent>
            <Typography variant="body2">:{price}₹ per km</Typography>
           
          </HoverContent>
        )}
      <CardContent>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </StyledCard>
    <CarPopup open={isPopupOpen} onClose={handleClosePopup} carDetails={{ title, price,type, imageUrl, carPackage }} />
    </SectionContainer>
  );
};

const cardStyle = {
  maxWidth: 345,
  margin: '20px',
  // Add any other styling as needed
};

const StyledCard = styled(Card)`
  maxWidth: 345px;
  margin: 20px;
  transition: transform 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

const mediaStyle = {
  objectFit: 'cover',
};

export default ResponsiveCard;
