// src/components/Hero.js
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Hero.css'; // Create this file for custom styles

const Hero = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const slides = [
    {
      image: require("./Images/lonavala.jpg"),
      caption: 'Explore the Beauty of Nature',
    },
    {
      image: require("./Images/mumbai.webp"),
      caption: 'Discover Amazing Destinations',
    },
    {
      image: require("./Images/bhimashankar.jpg"),
      caption: 'Create Lasting Memories',
    },
    {
      image: require("./Images/lavasa.webp"),
      caption: 'Plan Your Dream Vacation',
    },
    {
      image: require("./Images/People.jpeg"),
      caption: 'Plan Your Dream Vacation',
    },
    {
      image: require("./Images/People2.jpeg"),
      caption: 'Plan Your Dream Vacation',
    },
    {
      image: require("./Images/People3.jpeg"),
      caption: 'Plan Your Dream Vacation',
    },
  ];

  return (
    <div className="hero-container" style={{ marginTop: '10px', position: 'relative' }}>
      <Slider {...sliderSettings}>
        {slides.map((slide, index) => (
          <div key={index} style={{ position: 'relative', height: '500px' }} >
            <img   src={slide.image} alt={`Slide ${index + 1}`} />
            <div className="caption-overlay">
             
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Hero;
