import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import BookingPopup from './BookingPopup'; // Import the BookingPopup component

const LocalDetailsPopup = ({ open, onClose, details, carDetails, tripType }) => {
    const {  title, description, imageUrl , type, carPackage,carPackageLoc,price,extraHours, seats } = carDetails;
  const bulletPoints = [
    `8 hours or 80 km = Rs.${carPackageLoc}`,
    `Extra Kms- Rs. ${price} Per Km.`,
    `No. of seats: ${seats}, excluding driver`,
    'Booking Amount : Rs.700 Per Day.',
    'Extra-Toll & Parking-Boundary Tax.',
    'Extra – Driver allowance Rs. 300 Per Day.',
    `Extra Hours- Rs. ${extraHours} Per Hr.`,
    'Kms, Toll & Time calculate from our office to office – Maximum 20 Kms.'
  ];

  const [bookingPopupOpen, setBookingPopupOpen] = useState(false); // Add state for BookingPopup

  const handleBookClick = () => {
    setBookingPopupOpen(true);
    onClose(); // Close the CarPopup
  };

  const closeBookingPopup = () => {
    setBookingPopupOpen(false);
  };

  return (
    <>
    <Dialog open={open} onClose={onClose} aria-labelledby="local-details-title">
      <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Local Trip Details
        <Button onClick={onClose} color="error" style={{ minWidth: 'unset', marginLeft: 'auto' }}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent>
        {Object.entries(details).map(([key, value]) => (
          <DialogContentText key={key}>
            <strong>{key}:</strong> {value}
          </DialogContentText>
        ))}
        <DialogContentText>
          <strong>Additional Information:</strong>
          <ul>
            {bulletPoints.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleBookClick}>
          Book Now
        </Button>
      </DialogActions>
    </Dialog>
     {/* Include the BookingPopup */}
     <BookingPopup open={bookingPopupOpen} onClose={closeBookingPopup} carName={ title } tripType ={tripType} />
     </>
  );
};

export default LocalDetailsPopup;
